'use client';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useRouter } from 'next/navigation';
import { type PropsWithChildren, type ReactNode, Suspense, useEffect } from 'react';
import { msalConfig, useMsalLogger } from '../../common/util/employeeAuthConfig';
import { CustomNavigationClient } from './NavigationClient';

const msalInstance = new PublicClientApplication(msalConfig);

function useUpdateMsalNavigationClient() {
	const router = useRouter();

	// Setup navigation client
	useEffect(() => {
		if (!msalInstance) {
			return;
		}
		if (!router) {
			return;
		}
		const navigationClient = new CustomNavigationClient(router);
		msalInstance.setNavigationClient(navigationClient);
	}, [router]);
}

function useUpdateMsalLogger() {
	const msalLogger = useMsalLogger();

	useEffect(() => {
		if (!msalInstance) {
			return;
		}
		if (!msalLogger) {
			return;
		}
		msalInstance.setLogger(msalLogger);
	}, [msalLogger]);
}

export function MsalWrapper(props: PropsWithChildren): ReactNode {
	useEffect(() => {
		const msalInstancePromise = msalInstance.initialize();
		msalInstancePromise.catch((error) => {
			throw new Error('Unable to initialize authentication client', error);
		});
	}, []);

	useUpdateMsalNavigationClient();
	useUpdateMsalLogger();

	return (
		<MsalProvider instance={msalInstance}>
			<Suspense>{props.children}</Suspense>
		</MsalProvider>
	);
}
