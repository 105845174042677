'use client';

import type { JobResult } from '@dte/otw/azure-functions/src/common/interfaces/admin/search/JobResult';
import type { PremiseResult } from '@dte/otw/azure-functions/src/common/interfaces/admin/search/PremiseResult';
import type { SearchResults } from '@dte/otw/azure-functions/src/common/interfaces/admin/search/SearchResults';
import { getAddressLine1 } from '@dte/otw/azure-functions/src/common/premises/data/attributes/address/getAddressLine1';
import { getAddressLine2 } from '@dte/otw/azure-functions/src/common/premises/data/attributes/address/getAddressLine2';
import { filterEmpty } from '@dte/otw/azure-functions/src/common/util/lists/filterEmpty';
import { isEmpty } from '@dte/otw/azure-functions/src/common/util/objects/isEmpty';
import { lowerCaseValue } from '@dte/otw/azure-functions/src/common/util/strings/lowerCaseValue';
import { titleCaseValue } from '@dte/otw/azure-functions/src/common/util/strings/titleCaseValue';
import { upperCaseValue } from '@dte/otw/azure-functions/src/common/util/strings/upperCaseValue';
import { faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { type ReactElement, type ReactNode, useCallback, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { mutate } from 'swr';
import { useDebounce } from 'use-debounce';
import AuthenticatedJsonContent from '../../../app/(client)/(authenticated)/content/jsonGet/AuthenticatedJsonContent';
import { JobLink } from '../../../app/(client)/(authenticated)/jobs/(jobId)/jobLink/JobLink';
import { PremiseLink } from '../../../app/(client)/(authenticated)/premises/(premiseId)/premiseLink/PremiseLink';
import { PremiseMarker } from '../../../app/(client)/(authenticated)/premises/search/PremiseMarker';
import { usePathChanged } from '../../../hooks/usePathChanged';
import { FocusableContainer } from '../../FocusableContainer';
import { Icon } from '../../Spinner/Icon';
import { formatLongDateTime } from '../../Tables/formatShortDateTime';
import { getStableId } from '../../form/inputs/useStableUniqueId';
import { getSearchURL } from './getSearchURL';
import styles from './page.module.scss';

function refresh() {
	const path = '/authenticated/data';
	void mutate((key) => typeof key === 'string' && key.includes(path), undefined, { revalidate: true });
}

interface SearchProps {
	queryInput: string;
	setQueryInput: (string) => void;
	show: boolean;
}

function SearchInput(props: SearchProps): ReactNode {
	const queryInput = props.queryInput;
	const setQueryInput = props.setQueryInput;

	const changeQueryInput = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.currentTarget.value;
			setQueryInput(value);
		},
		[setQueryInput],
	);

	let placeholder = 'Search for Premise or Job';
	if (queryInput) {
		placeholder = '';
	}

	return (
		<Form.Control
			id="searchQuery"
			type="search"
			autoCapitalize="off"
			autoCorrect="off"
			autoComplete="off"
			spellCheck={false}
			onChange={changeQueryInput}
			value={queryInput}
			placeholder={placeholder}
			aria-controls="searchResults"
			aria-haspopup="menu"
		/>
	);
}

interface SearchResultsProps {
	queryInput: string;
	searchResults: SearchResults;
	show?: boolean;
	premiseResult?: PremiseResult;
	jobResult?: JobResult;
	loading?: boolean;
}

function longestTokenMatch(value: string, query: string): string {
	const lowerValue = lowerCaseValue(value);
	if (!lowerValue) {
		return undefined;
	}

	const tokens = query.split(' ');
	let longestMatch: string;

	for (const token of tokens) {
		// Do case insensitive matching
		const lowerToken = lowerCaseValue(token);

		// Only looking for things that start with the token
		if (!lowerValue.startsWith(lowerToken)) {
			continue;
		}

		// First match
		if (!longestMatch) {
			longestMatch = token;
		}

		// This token is longer than previous matches
		if (token.length > longestMatch.length) {
			longestMatch = token;
		}
	}

	return longestMatch;
}

interface HighlightedPartsProps {
	value: string;
	query: string;
	titleize?: boolean;
}

function HighlightedParts(props: HighlightedPartsProps): ReactNode {
	let value = props.value;
	if (!value) {
		return [<span key={0}>&nbsp;</span>];
	}

	if (props.titleize) {
		value = titleCaseValue(value);
	}
	const query = props.query;

	const valueParts = value.split(' ');

	const keyCount: Record<string, number> = {};
	const highlightedParts: ReactElement[] = valueParts.map((part, index) => {
		const token = longestTokenMatch(part, query);
		const tokenLength = token?.length;

		let ending = ' ';
		if (index === valueParts.length - 1) {
			ending = null;
		}

		// Get a consistent key
		let key = getStableId(part);
		if (keyCount[key]) {
			keyCount[key] += 1;
			key = `${key}-${keyCount[key]}`;
		} else {
			keyCount[key] = 1;
		}

		// No tokens matched
		if (!tokenLength) {
			return (
				<span key={key}>
					{part}
					{ending}
				</span>
			);
		}

		// Got a match
		const before = part.substring(0, tokenLength);
		const after = part.substring(tokenLength);
		return (
			<span key={key}>
				<b>{before}</b>
				{after}
				{ending}
			</span>
		);
	});

	return highlightedParts;
}

function shouldShowLine2(searchResults: SearchResults): boolean {
	const premises = searchResults?.premises;
	if (!premises) {
		return false;
	}

	const showLine2 = premises.find((findValue) => getAddressLine2(findValue?.premiseData?.data?.address));
	return showLine2 !== undefined;
}

function Line2Cell(props: SearchResultsProps): ReactNode {
	const premiseResult = props.premiseResult;
	if (!premiseResult) {
		return null;
	}

	const showLine2 = shouldShowLine2(props.searchResults);
	if (!showLine2) {
		return null;
	}

	const address = premiseResult?.premiseData?.data?.address;
	const queryInput = props.queryInput;
	return (
		<td>
			<PremiseLink premiseId={premiseResult.premiseId}>
				<HighlightedParts query={queryInput} value={getAddressLine2(address)} titleize={true} />
			</PremiseLink>
		</td>
	);
}

function PremiseResultDisplay(props: SearchResultsProps): ReactNode {
	const premiseResult = props.premiseResult;
	const premiseData = premiseResult?.premiseData;
	const address = premiseData?.data?.address;
	if (!address) {
		return null;
	}

	const queryInput = props.queryInput;

	return (
		<>
			<tr>
				<td className={styles.marker}>
					<PremiseMarker premiseData={premiseData} size="small">
						&nbsp;
					</PremiseMarker>
				</td>
				<td>
					<PremiseLink premiseId={premiseResult.premiseId}>
						<HighlightedParts query={queryInput} value={getAddressLine1(address)} titleize={true} />
					</PremiseLink>
				</td>
				<Line2Cell {...props} />
				<td>
					<HighlightedParts query={queryInput} value={address.city} titleize={true} />
				</td>
				<td>
					<HighlightedParts query={queryInput} value={address.state} />
				</td>
			</tr>
		</>
	);
}

function JobResultDisplay(props: SearchResultsProps): ReactNode {
	const jobResult = props.jobResult;
	const jobStatus = jobResult?.jobStatus;
	if (!jobStatus) {
		return null;
	}

	const jobDisplayId = upperCaseValue(jobStatus?.data?.jobDisplayId);
	const created = jobStatus?.data?.jobCreateTime;

	return (
		<tr>
			<td>
				<JobLink jobId={jobResult.jobId}>{jobDisplayId}</JobLink>
			</td>
			<td>{jobStatus.data?.jobType}</td>
			<td>{formatLongDateTime(created)}</td>
		</tr>
	);
}

function getJobSearchList(searchResults: SearchResults, queryInput: string): ReactNode {
	let jobs = searchResults?.jobs || [];
	// Just get the first 10 elements for display
	jobs = jobs.slice(0, 10);

	let jobOptions = jobs.map((job) => {
		return (
			<JobResultDisplay key={job.jobId} queryInput={queryInput} searchResults={searchResults} jobResult={job} />
		);
	});

	// Filter out anything with null values
	jobOptions = filterEmpty(jobOptions);
	if (isEmpty(jobOptions)) {
		return null;
	}

	return (
		<>
			<h4>Jobs</h4>
			<table>
				<thead className="sr-only">
					<tr>
						<th>Job</th>
						<th>Status</th>
						<th>Created Date</th>
						<th>Created Time</th>
						<th>Restored Date</th>
						<th>Restored Time</th>
					</tr>
				</thead>
				<tbody>{jobOptions}</tbody>
			</table>
		</>
	);
}

function getPremiseSearchList(searchResults: SearchResults, queryInput: string): ReactNode {
	let premises = searchResults?.premises || [];
	// Just get the first 10 elements for display
	premises = premises.slice(0, 10);

	let premiseOptions = premises.map((premise) => {
		return (
			<PremiseResultDisplay
				key={premise.premiseId}
				queryInput={queryInput}
				searchResults={searchResults}
				premiseResult={premise}
			/>
		);
	});

	// Filter out anything with null values
	premiseOptions = filterEmpty(premiseOptions);
	if (isEmpty(premiseOptions)) {
		return null;
	}

	const showLine2 = shouldShowLine2(searchResults);
	let line1Header = <th>Address</th>;
	let line2Header = null;
	if (showLine2) {
		line1Header = <th>Address Line 1</th>;
		line2Header = <th>Address Line 2</th>;
	}

	return (
		<>
			<h4>Premises</h4>
			<table>
				<thead className="sr-only">
					<tr>
						<th>Status</th>
						{line1Header}
						{line2Header}
						<th>City</th>
						<th>State</th>
					</tr>
				</thead>
				<tbody>{premiseOptions}</tbody>
			</table>
		</>
	);
}

function SearchResultsContent(props: SearchResultsProps): ReactNode {
	const queryInput = props.queryInput;
	const searchResults = props.searchResults;

	const premiseListContent = getPremiseSearchList(searchResults, queryInput);

	if (premiseListContent !== null) {
		return premiseListContent;
	}

	const jobListContent = getJobSearchList(searchResults, queryInput);
	if (jobListContent !== null) {
		return jobListContent;
	}

	const loading = props.loading;
	if (loading) {
		return <>Loading...</>;
	}

	if (queryInput) {
		return <>No matches</>;
	}

	return <>Enter an id or an address to search</>;
}

function SearchResultsSelection(props: SearchResultsProps): ReactNode {
	const show = props.show;

	const domId = getStableId('searchResults');

	let content = null;
	if (show) {
		content = (
			<div className={styles.content}>
				<SearchResultsContent {...props} />
			</div>
		);
	}

	return (
		<div id={domId} className={styles.selectionList} role="menu" aria-roledescription="Search Results">
			{content}
		</div>
	);
}

export default function SearchBar(): ReactNode {
	const [queryInput, setQueryInput] = useState<string>('');

	const [debouncedQueryInput] = useDebounce(queryInput, 100);
	const url = getSearchURL(debouncedQueryInput);

	const [searchResults, setSearchResults] = useState<SearchResults>();
	const [loading, setLoading] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);

	const handleShow = useCallback(() => {
		setShowOverlay(true);
	}, []);
	const handleHide = useCallback(() => {
		setShowOverlay(false);
	}, []);

	// Hide the results if we select something
	const changePath = useCallback(() => {
		// Clear the query
		setQueryInput('');

		// Hide the modal
		setShowOverlay(false);
	}, []);
	usePathChanged(changePath);

	const search = (event: React.FormEvent<HTMLFormElement>) => {
		if (!loading) {
			// On manually pressing search, clear caches and refresh
			refresh();
		}
		// Call prevent default becase we don't want to submit the form and cause a page refresh
		event.preventDefault();
	};

	let button = <Icon icon={faMagnifyingGlass} />;
	if (loading) {
		button = <Icon icon={faSpinner} spin={true} />;
	}

	return (
		<section className={styles.main}>
			<Form onSubmit={search}>
				<Form.Label htmlFor="searchQuery" className="sr-only">
					Search
				</Form.Label>
				<Col className={styles.inputColumn}>
					<FocusableContainer onFocus={handleShow} onBlur={handleHide}>
						<Button
							type="submit"
							className={styles.iconButton}
							title="Search"
							aria-controls="searchResults"
							aria-expanded={showOverlay}
						>
							{button}
						</Button>
						<SearchInput queryInput={queryInput} setQueryInput={setQueryInput} show={showOverlay} />
						<SearchResultsSelection
							queryInput={queryInput}
							searchResults={searchResults}
							loading={loading}
							show={showOverlay}
						/>
					</FocusableContainer>
					<AuthenticatedJsonContent
						url={url}
						setData={setSearchResults}
						setLoading={setLoading}
						loadingContent={null}
					/>
				</Col>
			</Form>
		</section>
	);
}
