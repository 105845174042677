'use client';
import type { PropsWithChildren, ReactNode } from 'react';
import { AppInsightsUserLogger } from '../AppInsightsWrapper/useAppInsightsUserLogger';
import NoSSR from '../NoSSR';
import { FullStoryUserLogger } from './FullStoryUserLogger';

// Keep track of the current user
export function UserLogger(props: PropsWithChildren): ReactNode {
	return (
		<>
			{props.children}
			<NoSSR>
				<AppInsightsUserLogger />
				<FullStoryUserLogger />
			</NoSSR>
		</>
	);
}
