import type { ReactNode } from 'react';
import { formatCount } from '../counts/formatCount';
import { percentage } from './percentage';
import styles from './simpleBar.module.scss';

export interface SimpleBarProps {
	className?: string;
	value: number;
	max: number;
}

export const SimpleBar = (props: SimpleBarProps): ReactNode => {
	const width = percentage(props.value, props.max);

	const classes = ['simpleBar'];
	if (width === 0) {
		classes.push('empty');
	}

	return (
		<>
			<div className={styles.main}>
				<span className={classes.join(' ')}>
					<span className={props.className} style={{ width: `${width}%` }} />
				</span>
			</div>
		</>
	);
};

export const SimpleBarCells = (props: SimpleBarProps): ReactNode => {
	return (
		<>
			<td>{formatCount(props.value)}</td>
			<td className={styles.tableCell}>
				<SimpleBar className={props.className} value={props.value} max={props.max} />
			</td>
		</>
	);
};
