'use client';

import type { ReactNode } from 'react';
import { BriefModeDetails } from '../../../Mode/BriefModeDetails';
import { BriefCountDetails } from '../../../counts/summary/BriefCountDetails';
import { OutageCountBar } from '../../../counts/summary/OutageCountBar';
import styles from './page.module.scss';

export default function MenuSummary(): ReactNode {
	return (
		<section className={styles.main}>
			<table>
				<tbody>
					<tr>
						<th>Mode</th>
						<td>
							<BriefModeDetails />
						</td>
					</tr>
					<tr>
						<th>Customers Out</th>
						<td>
							<BriefCountDetails />
						</td>
					</tr>
				</tbody>
			</table>
			<OutageCountBar />
		</section>
	);
}
