'use client';

import type { PropsWithChildren, ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { UserLogger } from '../../../components/FullStory/UserLogger';
import Header from '../../../components/header/Header';
import { AuthenticatedWrapper } from '../../../contexts/TokenContext/AuthenticatedEmployeeWrapper';
import { MsalWrapper } from '../../../contexts/TokenContext/MsalWrapper';

export default function AuthenticatedLayout(props: PropsWithChildren): ReactNode {
	return (
		<MsalWrapper>
			<UserLogger>
				<AuthenticatedWrapper>
					<Header />
					<Container className="bodyContent fullWidth">{props.children}</Container>
				</AuthenticatedWrapper>
			</UserLogger>
		</MsalWrapper>
	);
}
