'use client';

import type { PropsWithChildren, ReactNode } from 'react';
import type { AppAction } from '../../../../../contexts/TokenContext/Roles';
import { useIsAllowed } from '../../../../../contexts/TokenContext/useIsAllowed';

export interface RestrictedContentProps extends PropsWithChildren {
	action?: AppAction;
	fallback?: ReactNode;
}

export default function RestrictedContent(props: RestrictedContentProps): ReactNode {
	const action = props.action;
	const allowed = useIsAllowed(action);

	// Allowed isn't defined yet
	if (allowed === undefined) {
		return null;
	}

	if (!allowed) {
		let fallback = props.fallback;
		if (fallback === undefined) {
			fallback = null;
		}
		return fallback;
	}

	return props.children;
}
