'use client';

import { BrowserAuthError, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import {
	type IMsalContext,
	type MsalAuthenticationResult,
	MsalAuthenticationTemplate,
	useMsal,
} from '@azure/msal-react';
import { type PropsWithChildren, type ReactNode, Suspense, useLayoutEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getLoginMethod, useRedirectRequest } from '../../common/util/employeeAuthConfig';
import { logError } from '../../common/util/logging/writeLog';
import { useAppInsights } from '../../components/AppInsightsWrapper/AppInsightsWrapper';
import NoSSR from '../../components/NoSSR';
import { Spinner } from '../../components/Spinner/Spinner';
import { manualLogin } from './useAuthenticationResult';

const LoginError = (error: MsalAuthenticationResult): ReactNode => {
	const appInsights = useAppInsights();
	const { instance } = useMsal();

	const redirectRequest = useRedirectRequest();

	// If we got an error due to browser issues, try forcing a manual redirect
	useLayoutEffect(() => {
		if (!redirectRequest || !appInsights || !instance) {
			return;
		}
		if (error instanceof BrowserAuthError) {
			// Need to trigger redirect
			void manualLogin(appInsights, instance, redirectRequest);
		}
	}, [redirectRequest, error, appInsights, instance]);

	logError(appInsights, 'Login Error', { authenticationResult: error });

	let message = 'Error signing in';

	if (error.error?.message?.includes('AADSTS50105')) {
		message = 'Your account does not have access to this application.';
	}

	const loginMethod = getLoginMethod();
	if (error instanceof InteractionRequiredAuthError && loginMethod === InteractionType.Popup) {
		message =
			'Your browser may have blocked a pop-up required for login.  Please disable pop-up blocking for this site and refresh.';
	}

	return (
		<Container className="bodyContent">
			<Row className="error p-3">
				<Col>{message}</Col>
			</Row>
		</Container>
	);
};

const LoginInProgress = (_props: IMsalContext): ReactNode => {
	// TODO: does this need more?
	return <Spinner />;
};

export const AuthenticatedWrapper = (props: PropsWithChildren): ReactNode => {
	const loginMethod = getLoginMethod();
	const redirectRequest = useRedirectRequest();

	return (
		<NoSSR>
			<MsalAuthenticationTemplate
				interactionType={loginMethod}
				authenticationRequest={redirectRequest}
				errorComponent={LoginError}
				loadingComponent={LoginInProgress}
			>
				<Suspense fallback={<Spinner />}>{props.children}</Suspense>
			</MsalAuthenticationTemplate>
		</NoSSR>
	);
};
