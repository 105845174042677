'use client';

import { type ReactNode, useEffect } from 'react';
import { useMsalAccount } from '../../contexts/TokenContext/useMsalAccount';
import { useSetAuthenticatedUserContext } from './AppInsightsWrapper';

export function AppInsightsUserLogger(): ReactNode {
	const setUserContext = useSetAuthenticatedUserContext();
	const account = useMsalAccount();

	useEffect(() => {
		// Setter isn't ready
		if (!setUserContext) {
			return;
		}

		// No user avaiable
		const username = account?.username;
		if (!username) {
			return;
		}
		setUserContext(username);
	}, [setUserContext, account]);

	return null;
}
