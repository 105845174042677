'use client';
import { type CountSummary, CountType } from '@dte/otw/azure-functions/src/common/interfaces/containers/config/Count';
import { roundedString } from '@dte/otw/azure-functions/src/common/util/numbers/roundedString';
import { type ReactNode, useRef, useState } from 'react';
import { PopoverBody } from 'react-bootstrap';
import AuthenticatedJsonContent from '../../../app/(client)/(authenticated)/content/jsonGet/AuthenticatedJsonContent';
import { ContentWithPopup } from '../../../app/(client)/(authenticated)/orders/(orderId)/problemIndicators/RowWithSupplementalContent';
import { SimpleBar } from '../../SimpleBar/SimpleBar';
import { percentage } from '../../SimpleBar/percentage';
import styles from './OutageCountBar.module.scss';
import { getCountSummaryURL } from './getCountSummaryURL';

function roundPercentageWithoutPower(value: number, count: number): string {
	let rounded = roundedString(value, 2);

	// If 100% but we still have outages, show as 99.99%
	if (count > 0) {
		if (rounded === '100') {
			rounded = '99.99';
		}

		if (rounded === '0') {
			rounded = '0.01';
		}
	}

	return rounded;
}

export function OutageCountBar(): ReactNode {
	const [countSummary, setCountSummary] = useState<CountSummary>();
	const counts = countSummary?.counts || {};
	const containerRef = useRef(null);

	const url = getCountSummaryURL();

	const outageCount = counts[CountType.DisplayOutages];

	let message = null;

	let popup = null;
	if (outageCount !== undefined) {
		message = <>No customers out</>;

		if (outageCount > 0) {
			const premiseCount = counts[CountType.Premises];
			const percentOut = percentage(outageCount, premiseCount);
			const formatPercent = roundPercentageWithoutPower(percentOut, outageCount);
			message = (
				<>
					<b>{formatPercent}%</b> of customers out
				</>
			);
		}

		popup = <PopoverBody>{message}</PopoverBody>;
	}

	return (
		<>
			<section className={styles.main} ref={containerRef}>
				<ContentWithPopup
					popup={popup}
					triggerProps={{ delay: { show: 300, hide: 100 }, container: containerRef }}
				>
					<SimpleBar
						className="outages"
						value={counts[CountType.DisplayOutages]}
						max={counts[CountType.Premises]}
					/>
				</ContentWithPopup>
			</section>
			<AuthenticatedJsonContent url={url} setData={setCountSummary} />
		</>
	);
}
